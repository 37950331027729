//@ts-nocheck
import { Component } from "react";
import {
  conditionalParameter,
  mustBeArray,
  parseItems,
  errorHandler,
  getMatchParams,
} from "../../../libs/utilities";
import { Col, Layout, message, Row } from "antd";
import CustomNavbar from "../customNavbar";
import QuestionList from "./questionList";
import QuestionDetails from "./questionDetails";
import _ from "lodash";
import { DEFAULT_GUTTER } from "../../../constants/ui";
import axios from "axios";
import {
  REOCCURRING_AMENDMENT_TYPE_ID,
  REOCCURRING_STATUS_EDITED_ID,
} from "../../../constants/payrun";
import { withRouter } from "../../../hooks";
import { connect } from "react-redux";

const { Header, Content } = Layout;

class AmendmentQuestions extends Component<{}, any> {
  signal = axios.CancelToken.source();
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      activeQuestion: 0,
      listLoading: false,
      amendment: {},
      amendmentSummary: {
        questions: [],
      },
      holidays: {},
    };
  }
  componentDidMount() {
    this.listHolidaysPeriod();
    let { match } = this.props;

    const id = getMatchParams({ match, field: "id" });
    this.setState({ listLoading: true });
    this.props
      .getAmendment({ id })
      .then((resp) => {
        this.setState({ listLoading: false });
        if (resp.status && resp.data) {
          let amendmentSummary = parseItems(resp.data.amendmentSummary);
          let questions = mustBeArray(
            conditionalParameter({ data: amendmentSummary, field: "questions" })
          ).reduce((a: any, b: any) => {
            return _.concat(a, [
              b && b.name === "Amount" && !b.value
                ? { ...b, value: resp.data.amount }
                : { ...b },
            ]);
          }, []);
          const hasBeenEdited = _.find(mustBeArray(questions), (o) => o.value);
          if (!hasBeenEdited) {
            questions = questions.map((question) => {
              if (question.threshold?.default) {
                question.value = question.threshold.default;
              }
              return question;
            });
          }

          this.setState({
            amendment: resp.data,
            amendmentSummary: { ...amendmentSummary, questions },
          });
        } else {
          errorHandler({ response: resp, hasValidationErrors: true });
        }
      });
  }

  listHolidaysPeriod = () => {
    this.props
      .cdnLookUpHandler({ options: {}, type: "holidaysPeriod" })
      .then((resp) => {
        this.setState({
          holidays: conditionalParameter({ data: resp, field: "data" }),
        });
      });
  };

  inputValueChange = (value, subindex) => {
    this.setState((prevState) => {
      if (_.isUndefined(subindex)) {
        prevState.amendmentSummary.questions[this.state.activeQuestion].value =
          value;
      } else {
        prevState.amendmentSummary.questions[this.state.activeQuestion].options[
          subindex
        ].value = value;
      }

      this.forceUpdate();
      return prevState;
    });
  };

  toggleActiveKey = (newKey) => {
    this.setState({ activeQuestion: newKey });
  };

  updateActiveKey = (direction) => {
    switch (direction) {
      case "next":
        this.setState({
          activeQuestion: Number(this.state.activeQuestion) + 1,
        });
        break;
      case "back":
        this.setState({
          activeQuestion: Number(this.state.activeQuestion) - 1,
        });
        break;
      default:
        break;
    }
  };

  handleCancel = () => {
    const { header } = this.props;
    this.props.router.navigate(header.returnUrl);
  };

  handleSave = () => {
    this.setState({ loading: true });
    let { match } = this.props;
    const payrunId = getMatchParams({ match, field: "payrunId" });
    const id = getMatchParams({ match, field: "id" });

    this.setState(
      (prevState) => {
        prevState.amendment.amendmentSummary = JSON.stringify(
          this.state.amendmentSummary
        );
        return prevState;
      },
      () => {
        let values = payrunId
          ? this.state.amendment
          : {
              amendmentSummary: this.state.amendment?.amendmentSummary,

              assignTo: this.state.amendment?.assignTo,
            };

        if (
          this.state.amendment &&
          this.state.amendmentTypeId === REOCCURRING_AMENDMENT_TYPE_ID
        ) {
          values.amendmentStatusInPPSId = REOCCURRING_STATUS_EDITED_ID;
        }

        this.props
          .updateAmendment({
            id,
            payrunId,
            values,
          })

          .then((resp) => {
            this.setState({ loading: false });

            if (resp.status) {
              message.success("Amendment updated successfully");
              if (header.returnUrl) {
                this.props.router.navigate(header.returnUrl);
              } else {
                this.props.router.navigate("/generalAmendments");
              }
            } else {
              errorHandler({
                response: resp,
                oldModel: false,
                hasValidationErrors: true,
              });
            }
          });
      }
    );
  };

  uploadAmendmentFile = (file) => {
    let { match } = this.props;
    const payrunId = getMatchParams({ match, field: "payrunId" });
    const id = getMatchParams({ match, field: "id" });

    this.props
      .uploadAmendmentFile({ id, payrunId, values: { file } })

      .then((resp) => {
        if (resp.status) {
          message.success("File uploaded successfully");

          this.setState((prevState) => {
            const url = parseItems(resp.data)?.result;
            prevState.amendmentSummary.questions[
              this.state.activeQuestion
            ].value = [url];
            return prevState;
          });
        } else {
          errorHandler({
            response: resp,
            oldModel: false,
            hasValidationErrors: true,
          });
        }
      });
  };
  handleDelete = () => {
    let { match } = this.props;
    const payrunId = getMatchParams({ match, field: "payrunId" });
    const id = getMatchParams({ match, field: "id" });
    if (payrunId) {
      return this.props
        .deleteAmendment({ id, options: { payrunID: payrunId } })

        .then((resp) => {
          return resp;
        });
    } else {
      return this.props
        .generalAmendmentHandler({
          id,
          cancelToken: this.signal.token,
          action: "delete",
        })
        .then((resp) => {
          return resp;
        });
    }
  };

  dropDownWithTextChange = (value) => {
    this.setState((prevState) => {
      prevState.amendmentSummary.questions[
        this.state.activeQuestion
      ].textValue = value;
      return prevState;
    });
  };

  render() {
    return (
      <Layout className="amendment-questions">
        <Header className="bg-white w-full position-absolute-top">
          <CustomNavbar
            handleCancel={this.handleCancel}
            handleSave={this.handleSave}
            loading={this.state.loading}
            handleDelete={this.handleDelete}
            hideEnableBack={true}
            pageTitle="Amendment"
          />
        </Header>
        <Layout>
          <Content
            className={`p-6 full-height ${
              this.state.responsive ? "pr-[15px] pl-[15px]" : "pr-[15px]"
            }`}
          >
            <Row gutter={DEFAULT_GUTTER} className="mx-0">
              <Col span={8} className="ant-card bg-white">
                <QuestionList
                  toggleActiveKey={this.toggleActiveKey}
                  questionList={mustBeArray(
                    this.state.amendmentSummary?.questions
                  )}
                  businessunitDetails={this.props.businessunitDetails}
                  {...this.state}
                />
              </Col>
              <Col span={16} className="pr-0 pl-6">
                <QuestionDetails
                  index={this.state.activeQuestion}
                  question={
                    this.state.amendmentSummary?.questions?.[
                      this.state.activeQuestion
                    ]
                  }
                  inputValueChange={this.inputValueChange}
                  dropDownWithTextChange={this.dropDownWithTextChange}
                  total={this.state.amendmentSummary?.questions?.length}
                  updateActiveKey={this.updateActiveKey}
                  uploadAmendmentFile={this.uploadAmendmentFile}
                  businessunitDetails={this.props.businessunitDetails}
                  holidays={this.state.holidays}
                />
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    header: state.pageHeader.header,
  };
}

export default connect(mapStateToProps, null)(withRouter(AmendmentQuestions));
